import {Controller} from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
    static targets = ['input', 'validateMessage', 'hiddenInput']
    static values = {utilsPath: String, errorMessage: {type: String, default: 'Please enter a valid phone number'}}

    initialize() {
        this.load()
    }

    load() {
        this._locale = document.documentElement.lang === 'en' ? 'en' : 'de'
        this._initialCountry = document.documentElement.lang === 'en' ? 'de' : 'auto'

        if (this._iti === undefined) {
            this._iti = intlTelInput(this.inputTarget, {
                onlyCountries: ['de', 'at', 'be', 'dk', 'fi', 'fr', 'it', 'lu', 'nl', 'es', 'se'],
                localizedCountries: this.getLocalizedCountries(this._locale),
                nationalMode: true,
                initialCountry: this._initialCountry,
                autoPlaceholder: 'off',
                geoIpLookup: callback => {
                    callback(this._locale)
                },
                utilsScript: this.utilsPathValue
            })
        }

        return this._iti
    }

    validate(_event) {
        let isValid = this._iti.isValidNumber()
        if (isValid === null) {
            this.validateMessageTarget.innerHTML = ''
            this.inputTarget.setCustomValidity(this.errorMessageValue)
        } else if (isValid) {
            this.validateMessageTarget.innerHTML = '<i class="fa-solid fa-circle-check text-success"></i>'
            this.inputTarget.setCustomValidity('')
            this.hiddenInputTarget.value = this._iti.getNumber()
        } else {
            let errorMessage = this.getLocalizedErrorMessages(this._locale)[this._iti.getValidationError()] || ''
            this.validateMessageTarget.innerHTML = `<i class="fa-solid fa-circle-xmark text-danger"></i> ${errorMessage}`
            this.inputTarget.setCustomValidity(this.errorMessageValue)
        }
    }

    getLocalizedCountries(locale) {
        return {
            de: {
                de: 'Deutschland',
                at: 'Österreich',
                be: 'Belgien',
                dk: 'Dänemark',
                fi: 'Finnland',
                fr: 'Frankreich',
                it: 'Italien',
                lu: 'Luxemburg',
                nl: 'Niederlande',
                es: 'Spanien',
                se: 'Schweden',
            },
            en: {
                de: 'Germany',
                at: 'Austria',
                be: 'Belgium',
                dk: 'Denmark',
                fi: 'Finland',
                fr: 'France',
                it: 'Italy',
                lu: 'Luxembourg',
                nl: 'Netherlands',
                es: 'Spain',
                se: 'Sweden',
            }
        }[locale]
    }

    getLocalizedErrorMessages(locale) {
        return {
            de: {
                1: 'Ungültige Landesvorwahl', // INVALID_COUNTRY_CODE
                5: 'Die Länge der Rufnummer ist nicht korrekt', // INVALID_LENGTH
                0: 'Die Länge der Rufnummer ist nicht korrekt', // IS_POSSIBLE
                4: 'Die Länge der Rufnummer ist nicht korrekt', // IS_POSSIBLE_LOCAL_ONLY
                3: 'Rufnummer zu lang', // TOO_LONG
                2: 'Rufnummer zu kurz'  // TOO_SHORT
            },
            en: {
                1: 'Invalid country calling code', // INVALID_COUNTRY_CODE
                5: 'The length of the phone number is not correct', // INVALID_LENGTH
                0: 'The length of the phone number is not correct', // IS_POSSIBLE
                4: 'The length of the phone number is not correct', // IS_POSSIBLE_LOCAL_ONLY
                3: 'Phone number too long', // TOO_LONG
                2: 'Phone number too short'  // TOO_SHORT
            }
        }[locale]
    }
}

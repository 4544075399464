import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['image']
    static values = {hoverImgUrl: String}

    initialize() {
        this._switchImage = this._switchImage.bind(this)
    }

    connect() {
        this.element.addEventListener('mouseover', this._switchImage)
        this.element.addEventListener('mouseout', this._switchImage)
    }

    disconnect() {
        this.element.removeEventListener('mouseover', this._switchImage)
        this.element.removeEventListener('mouseout', this._switchImage)
    }

    _switchImage(_event) {
        if (!this.hasHoverImgUrlValue || this.hoverImgUrlValue === '/' || this.hoverImgUrlValue === '') return

        const src = this.imageTarget.src
        this.imageTarget.src = this.hoverImgUrlValue
        this.hoverImgUrlValue = src
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['select', 'button']

    connect() {
        // Handle prefilled selectpicker targets with value.
        if (this.selectTarget.value) {
            const target = this.selectTarget.closest('.dropdown')
                .querySelector(`a[data-value="${this.selectTarget.value}"]`)

            this.configureDropdownLink(target)
        }
    }

    select(event) {
        let target = event.target

        // Set hidden input field value.
        this.selectTarget.value = target.dataset.value
        // Set hidden input value + manage css classes.
        this.configureDropdownLink(target)
        // Call 'input' event on hidden input field.
        this.selectTarget.dispatchEvent(new Event('input'))

        event.preventDefault()
    }

    configureDropdownLink(target) {
        // Remove 'active' class from all dropdown links.
        target.closest('.dropdown-menu').querySelectorAll('a').forEach(selectlinks => {
            selectlinks.classList.remove('active')
        })
        // Add 'active' class for current dropdown link.
        target.classList.add('active')
        // Set selected text to main dropdown trigger button.
        this.buttonTarget.querySelector('span').innerText = target.dataset.placeholder || target.innerText
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        const key = ['m', 'a', 'i', 'l', 't', 'o', ':']
        const value = ['h', 'e', 'l', 'l', 'o', '@', 'f', 'o', 'b', 'e', '.', 'm', 'e']
        this.element.href = key.concat(value).join('')
        this.element.innerText = value.join('')
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['muteButton', 'videoElement']
    static values = {
        muted: {type: Boolean, default: true}
    }

    connect() {
        this.videoElementTarget.muted = this.mutedValue
        this.videoElementTarget.volume = 0.5
        try {
            this.videoElementTarget.play()
        } catch (_error) {
            // Catch "The request is not allowed by the user agent or the platform in the current context, possibly
            // because the user denied permission."
        }
    }

    toggleMute(_event) {
        this.videoElementTarget.muted = !this.videoElementTarget.muted
        this.muteButtonTarget.dataset.muted = this.videoElementTarget.muted
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['shareButton', 'disableElement']
    static values = {body: String, title: String}

    connect() {
        if (this._shareSupport()) {
            this.element.classList.add('share--supported')
            this.disableElementTarget.style.display = 'none'
        } else {
            this.shareButtonTarget.style.display = 'none'
        }
    }

    async share(e) {
        e.preventDefault()
        const shareData = {
            title: this.titleValue,
            text: this.bodyValue
        }

        await navigator.share(shareData)
    }

    _shareSupport() {
        return !!navigator.canShare
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['item']
    static classes = ['d-none']

    connect() {
        this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none'
    }

    toggle() {
        this.itemTargets.forEach(item => {
            item.classList.toggle(this.class)
            this._updateFormElementStatus(item, item.classList.contains(this.class))
        })
    }

    show() {
        this.itemTargets.forEach(item => {
            item.classList.remove(this.class)
            this._updateFormElementStatus(item, false)
        })
    }

    hide() {
        this.itemTargets.forEach(item => {
            item.classList.add(this.class)
            this._updateFormElementStatus(item, true)
        })
    }

    _updateFormElementStatus(item, status) {
        item.querySelectorAll('input, select, checkbox, textarea').forEach((element) => {
            element.disabled = status
        })
    }
}
import {Controller} from '@hotwired/stimulus'
import Splide from '@splidejs/splide';

export default class extends Controller {
    static values = {
        start: {type: Number, default: 0},
        pagination: {type: Boolean, default: false},
        perPage: {type: Number, default: 3},
        gap: {type: String, default: '1rem'},
        trimSpace: {type: Boolean, default: true},
        focus: {type: String, default: ''},
        type: {type: String, default: 'loop'},
        autoWidth: {type: Boolean, default: false},
        breakpoints: {type: Object, default: {}}
    }

    initialize() {
        this._resize = this._resize.bind(this)
    }

    connect() {
        this._slider = new Splide(this.element, {
            start: this.startValue,
            pagination: this.paginationValue,
            type: this.typeValue,
            gap: this.gapValue,
            trimSpace: this.trimSpaceValue,
            perPage: this.perPageValue,
            lazyLoad: 'nearby',
            autoWidth: this.autoWidthValue,
            focus: this.focusValue,
            mediaQuery: 'min',
            breakpoints: this.breakpointsValue
        }).mount();

        this.element.addEventListener('shown.bs.tab', this._resize)
    }

    disconnect() {
        this.element.removeEventListener('shown.bs.tab', this._resize)
    }

    _resize(event) {
        this._slider.emit('resize')
    }
}

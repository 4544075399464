import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {targetId: String, triggerInvalid: String}

    initialize() {
        this._handleInput = this._handleInput.bind(this)
    }

    connect() {
        document.getElementById(this.targetIdValue).addEventListener('input', this._handleInput)
    }

    disconnect() {
        document.getElementById(this.targetIdValue)?.removeEventListener('input', this._handleInput)
    }

    _handleInput(event) {
        if (event.target.value === this.triggerInvalidValue) {
            this.element.parentElement.classList.add('required')
            this.element.required = true
        } else {
            this.element.parentElement.classList.remove('required')
            this.element.required = false
        }
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {cssClasses: {type: Array, default: []}}
    static targets = ['target']

    connect() {
        this.intersectionObserver = new IntersectionObserver(([entry]) => {
                if (this.hasTargetTarget) {
                    this.cssClassesValue.map(css_class => this.targetTarget.classList.toggle(css_class, entry.intersectionRatio < 1))
                } else {
                    entry.target.classList.toggle(this.cssClassesValue, entry.intersectionRatio < 1)
                }
            }, {threshold: [1]}
        )

        this.intersectionObserver.observe(this.element)
    }

    disconnect() {
        this.intersectionObserver.unobserve(this.element)
    }
}

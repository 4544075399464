import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {faTiktok, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {
    faTrashCan,
    faEnvelope as farEnvelope,
    faCircle,
    faCopyright,
    faPenToSquare,
    faFile,
    faHeart as farHeart
} from '@fortawesome/free-regular-svg-icons'
import {
    faDownload,
    faArrowLeftLong,
    faArrowRightLong,
    faArrowsAltV,
    faBan,
    faUser,
    faUsers,
    faSignOutAlt,
    faTachometerAlt,
    faShoppingBag,
    faRetweet,
    faGears,
    faEllipsisV,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faXmark,
    faMagnifyingGlass,
    faPlus,
    faMinus,
    faLock,
    faHandHoldingDollar,
    faTriangleExclamation,
    faCircleInfo,
    faInfo,
    faCartShopping,
    faClipboard,
    faCheck,
    faVolumeXmark,
    faVolumeHigh,
    faHeart,
    faHeartCrack,
    faCircleCheck,
    faCircleXmark,
    faListCheck,
    faEye,
    faEyeSlash,
    faDiagramNext
} from '@fortawesome/free-solid-svg-icons'

document.addEventListener('DOMContentLoaded', function (_event) {
    library.add(
        faTrashCan, faDownload, faArrowLeftLong, faArrowRightLong, faArrowsAltV, faUser, faUsers, faSignOutAlt,
        faTachometerAlt, faShoppingBag, faRetweet, faGears, faEllipsisV, faChevronLeft, faChevronRight, faChevronUp,
        faChevronDown, faXmark, faBan, faMagnifyingGlass, faPlus, faMinus, farEnvelope, faCircle, faLock, faTiktok,
        faInstagram, faCopyright, faHandHoldingDollar, faTriangleExclamation, faCircleInfo, faInfo, faCartShopping,
        faClipboard, faPenToSquare, faFile, faCheck, faVolumeXmark, faVolumeHigh, faHeart, farHeart, faHeartCrack,
        faCircleCheck, faCircleXmark, faListCheck, faEye, faEyeSlash, faDiagramNext
    )
    dom.watch()
})

import {Controller} from '@hotwired/stimulus'
import debounce from 'debounce'

export default class extends Controller {
    static targets = ['requestSubmit']

    initialize() {
        this.handleSubmit = debounce(this.handleSubmit, 500)
        this.handleSubmitInstant = debounce(this.handleSubmitInstant, 10)
        this.requestSubmitTargetConnected = debounce(this.requestSubmitTargetConnected, 10)
    }

    handleSubmit(event) {
        if (!this.element.reportValidity()) return

        this.element.requestSubmit()
    }

    handleSubmitInstant() {
        if (!this.element.reportValidity()) return

        this.element.requestSubmit()
    }

    submit() {
        this.element.requestSubmit()
    }

    requestSubmitTargetConnected(_element) {
        this.submit()
    }
}

import {Controller} from '@hotwired/stimulus'
import {Loader} from '@googlemaps/js-api-loader'

export default class extends Controller {
    static targets = ['input', 'line1', 'line2', 'postalCode', 'locality', 'country']
    static values = {apiKey: String}

    initialize() {
        this._placeChanged = this._placeChanged.bind(this)
        this._onKeyDown = this._onKeyDown.bind(this)
    }

    connect() {
        const loader = new Loader({
            apiKey: this.apiKeyValue,
            version: 'quarterly',
            libraries: ['places']
        })

        this.inputTarget.addEventListener('keydown', this._onKeyDown)
        this._initializePlaces(loader)
    }

    disconnect() {
        this.inputTarget.removeEventListener('keydown', this._onKeyDown)
    }

    _initializePlaces(loader) {
        loader
            .load()
            .then((google) => {
                this._autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
                    fields: ['address_components', 'geometry'],
                    types: ['address']
                })

                this._autocomplete.addListener('place_changed', this._placeChanged)
            })
            .catch((_error) => {
                console.log(_error)
                console.log('error loading places')
            })
    }

    _onKeyDown(event) {
        if (event.keyCode === 13) event.preventDefault()
    }

    _placeChanged(event) {
        const place = this._autocomplete.getPlace()
        if (place.address_components === undefined) return

        // Reset all fields to prevent previously entered values from distorting the result.
        this.line1Target.value = ''
        this.postalCodeTarget.value = ''
        this.localityTarget.value = ''
        this.countryTarget.value = null

        for (const component of place.address_components) {
            const componentType = component.types[0]
            switch (componentType) {
                case 'street_number': {
                    this.line1Target.value = component.long_name
                    break
                }
                case 'route': {
                    this.line1Target.value = `${component.long_name} ${this.line1Target.value}`
                    break
                }
                case 'postal_code': {
                    this.postalCodeTarget.value = component.long_name
                    break
                }
                case 'locality':
                    this.localityTarget.value = component.long_name
                    break
                case 'country':
                    let selectableOptions = [...this.countryTarget.options].map((option) => {
                        return option.value
                    })
                    if (selectableOptions.includes(component.short_name)) {
                        this.countryTarget.value = component.short_name
                    } else {
                        this.countryTarget.value = null
                    }
                    break
            }
        }

    }
}

/*
  Filterable Stimulus Controller v0.0.1

  You can filter all kinds of Dom Elements from given an input element which triggers the filter function.
  Every element specified with the 'data-filterable-target="element"' attribute will be considers for the filter.
  You can set a 'data-filterable-target="curriculum"' in which a text is displayed when the filter does not match any
  element.

  ATTENTION:
    The "noEntriesHint" value must have the css class "filterable-no-entries".
 */
import {Controller} from '@hotwired/stimulus'
import debounce from 'debounce'

export default class extends Controller {
    static values = {
        noEntriesHint: {
            type: String,
            default: '<p class="text-center text-body-secondary filterable-no-entries">No entries found</p>'
        }
    }
    static targets = ['element', 'curriculum', 'noEntries']

    initialize() {
        this.filter = debounce(this.filter, 500)
    }

    filter(event) {
        const inputValue = event.target.value.trim().toLowerCase()
        Array.prototype.forEach.call(this.elementTargets, (element) => {
            if (element.textContent.toLowerCase().indexOf(inputValue) > -1) {
                element.classList.remove('visually-hidden')
            } else {
                element.classList.add('visually-hidden')
            }
        })
        this._handleNoEntriesHint()
    }

    _handleNoEntriesHint() {
        Array.prototype.forEach.call(this.curriculumTargets, (element) => {
            if (element.querySelectorAll(':scope > div:not(.visually-hidden)').length === 0) {
                if (element.querySelectorAll('.filterable-no-entries').length === 0) {
                    element.insertAdjacentHTML('beforeend', this.noEntriesHintValue.trim())
                }
            } else {
                Array.prototype.forEach.call(element.querySelectorAll('.filterable-no-entries'), (node) => {
                    node.parentNode.removeChild(node)
                })
            }
        })
    }
}

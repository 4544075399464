import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['chargebeeTokenId', 'submitButton', 'creditCard', 'payPal', 'applePay',
        'applePaySelection', 'googlePay']
    static values = {
        errorMessage: String,
        siteName: String,
        publishableKey: String,
        paymentIntent: Object,
        paymentMethodFieldName: {type: String, default: 'users_subscription[chosen_payment_method]'},
        creditCardNumberFieldSelector: {type: String, default: '#card-number'},
        creditCardExpiryFieldSelector: {type: String, default: '#card-expiry'},
        creditCardCvvFieldSelector: {type: String, default: '#card-cvc'},
        walletButtonSelector: {type: String, default: '#wallet-button'}
    }

    initialize() {
        this._mountChargebee()

        if (this.hasApplePaySelectionTarget && window.ApplePaySession) {
            this.applePaySelectionTarget.classList.remove('d-none')
        }

        if (this.hasCreditCardTarget) this.mountCardComponent()
        if (this.hasPayPalTarget) this.mountPayPalComponent()
        if (this.hasApplePayTarget) this.mountApplePayComponent()
        if (this.hasGooglePayTarget) this.mountGooglePayComponent()
    }

    mountCardComponent() {
        let locale = document.documentElement.lang === 'en' ? 'en' : 'de'
        this._chargebeeInstance.load('components').then(() => {
            this._cardComponent = this._chargebeeInstance.createComponent('card', this._cardComponentOptions(locale))
            this._cardComponent.createField('number').at(this.creditCardNumberFieldSelectorValue)
            this._cardComponent.createField('expiry').at(this.creditCardExpiryFieldSelectorValue)
            this._cardComponent.createField('cvv').at(this.creditCardCvvFieldSelectorValue)
            this._cardComponent.mount()

            this._cardComponent.on('change', this._handleStateChange.bind(this))
        })
    }

    mountPayPalComponent() {
        let locale = document.documentElement.lang === 'en' ? 'en_US' : 'de_DE'
        let paypalHandler
        this._chargebeeInstance
            .load('paypal')
            .then((handler) => {
                paypalHandler = handler
                paypalHandler.setPaymentIntent(this.paymentIntentValue)
                return paypalHandler.mountPaymentButton(this.walletButtonSelectorValue,
                    this._paypalComponentOptions(locale))
            })
            // Wait for payment authorization to complete
            .then(() => paypalHandler.handlePayment())
            .then((authorizedPaymentIntent) => {
                // Use this authorized payment intent to create a subscription
                document.getElementById('users_subscription_chargebee_payment_intent_id')
                    .value = authorizedPaymentIntent.id
                document.getElementById('checkout_form').submit()
            })
            .catch((error) => {
                this.#showChargebeeNotAvailableGrowl()
                console.log('error', error)
            })
    }

    mountApplePayComponent() {
        let locale = document.documentElement.lang === 'en' ? 'en_US' : 'de_DE'
        let applePayHandler

        this._chargebeeInstance
            .load('apple-pay')
            .then((handler) => {
                applePayHandler = handler

                if (!applePayHandler.canMakePayments()) {
                    throw new Error('Apple Pay not available')
                }

                applePayHandler.setPaymentIntent(this.paymentIntentValue)
                return applePayHandler.mountPaymentButton(this.walletButtonSelectorValue,
                    this._applePayComponentOptions(locale))
            })
            // Wait for payment authorization to complete
            .then(() => applePayHandler.handlePayment())
            .then((authorizedPaymentIntent) => {
                // Use this authorized payment intent to create a subscription
                document.getElementById('users_subscription_chargebee_payment_intent_id')
                    .value = authorizedPaymentIntent.paymentIntent.id
                document.getElementById('checkout_form').submit()
            })
            .catch((error) => {
                this.#showChargebeeNotAvailableGrowl()
                console.log('error', error)
            })
    }

    mountGooglePayComponent() {
        let locale = document.documentElement.lang
        let googlePayHandler

        this._chargebeeInstance
            .load('google-pay')
            .then((handler) => {
                googlePayHandler = handler

                googlePayHandler.setPaymentIntent(this.paymentIntentValue)
                return googlePayHandler.mountPaymentButton(this.walletButtonSelectorValue,
                    this._googlePayComponentOptions(locale))
            })
            // Wait for payment authorization to complete
            .then(() => googlePayHandler.handlePayment())
            .then((authorizedPaymentIntent) => {
                // Use this authorized payment intent to create a subscription
                document.getElementById('users_subscription_chargebee_payment_intent_id')
                    .value = authorizedPaymentIntent.paymentIntent.id
                document.getElementById('checkout_form').submit()
            })
            .catch((error) => {
                if (error.message !== 'User closed the Payment Request UI.') {
                    this.#showChargebeeNotAvailableGrowl()
                    console.log('error', error)
                }
            })
    }

    submitPaymentMethod(event) {
        event.preventDefault()

        this._disableSubmitButton()
        switch (this._getSelectedPaymentMethod()) {
            case 'credit_card':
                if (this.chargebeeTokenIdTarget.value) {
                    this.element.submit()
                } else {
                    this._cardComponent.tokenize().then(data => {
                        this.chargebeeTokenIdTarget.value = data.token
                        this.element.submit()
                    }).catch(error => {
                        this._enableSubmitButton()
                        this.#showChargebeeNotAvailableGrowl()
                        console.log(error)
                    })
                }
                break
            case 'paypal':
            case 'apple_pay':
            case 'google_pay':
                this.element.submit()
                break
            default:
                // Handle other cases.
                break
        }
    }

    /**
     * Opens a portal session for chargebee.
     * This is used to open the chargebee popup on the user profile pages.
     * @param {Event} event - The event object that triggered the method.
     * @return {void}
     */
    openPortalSession(event) {
        event.preventDefault()

        this._setPortalSession()
        let chargebeePortal = this._chargebeeInstance.createChargebeePortal()
        chargebeePortal.open({
            error: (error) => {
                console.log(error)
                this.#showChargebeeNotAvailableGrowl()
            }
        })
    }

    _mountChargebee() {
        if (this._chargebeeInstance === undefined) {
            this._chargebeeInstance = Chargebee.init({
                site: this.siteNameValue,
                publishableKey: this.publishableKeyValue
            })
        }
    }

    _setPortalSession() {
        this._chargebeeInstance.setPortalSession(() => {
            return fetch(`/chargebee/portal_session`).then(response => response.json())
        })
    }

    _getSelectedPaymentMethod() {
        let radioButtonGroup = document.getElementsByName(this.paymentMethodFieldNameValue)
        let checkedRadio = Array.from(radioButtonGroup).find((radio) => radio.checked)
        return checkedRadio ? checkedRadio.value : undefined
    }

    _handleStateChange(currentState) {
        const fieldSelectors = {
            'number': this.creditCardNumberFieldSelectorValue,
            'expiry': this.creditCardExpiryFieldSelectorValue,
            'cvv': this.creditCardCvvFieldSelectorValue
        }
        let field = document.querySelector(fieldSelectors[currentState.field])
        if (field === undefined) return

        let validationFeedback = field.parentElement.querySelector('.invalid-feedback')
        if (!currentState.complete && currentState.error !== undefined) {
            validationFeedback.innerText = currentState?.error?.message
        }
    }

    _enableSubmitButton() {
        this.submitButtonTargets.forEach((button) => button.disabled = false)
    }

    _disableSubmitButton() {
        this.submitButtonTargets.forEach((button) => button.disabled = true)
    }

    _cardComponentOptions(locale) {
        return {
            currency: 'EUR', locale: locale, icon: true, classes: {
                focus: 'focus', invalid: 'invalid', empty: 'empty', complete: 'complete',
            }, placeholder: {
                number: ' ', expiry: ' ', cvv: ' '
            }, style: {
                base: {fontSize: '1rem'},
                invalid: {color: '#dc3545', ':focus': {color: '#dc3545'}, iconColor: '#dc3545'}
            }
        }
    }

    _paypalComponentOptions(locale) {
        return {
            locale: locale,
            style: {shape: 'rect', color: 'black', label: 'pay'}
        }
    }

    _applePayComponentOptions(locale) {
        return {
            locale: locale,
            buttonColor: 'black',
            buttonType: 'buy'
        }
    }

    _googlePayComponentOptions(locale) {
        return {buttonColor: 'black', buttonType: 'buy', buttonSizeMode: 'fill', buttonLocale: locale}
    }

    #showChargebeeNotAvailableGrowl() {
        document.getElementById('flashNotices').insertAdjacentHTML('beforeend', `<div class="toast align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true" data-controller="toast" data-bs-delay="5000"><div class="d-flex"><div class="toast-body">${this.errorMessageValue}</div><button name="button" type="button" class="btn-close btn-close-white me-2 m-auto" aria-label="close" data-bs-dismiss="toast"></button></div></div>`)
    }
}

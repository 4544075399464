import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['output', 'output2']

    printToOutput(event) {
        this.processEvent(event, this.outputTarget)
    }

    printToOutput2(event) {
        this.processEvent(event, this.output2Target)
    }

    processEvent(event, outputTarget) {
        if (outputTarget.type === 'text' || outputTarget.type === 'hidden') {
            outputTarget.value = event.target.value
        } else {
            outputTarget.textContent = event.target.value
        }
    }
}
import {Controller} from '@hotwired/stimulus'
import Swiper from 'swiper'
import {Autoplay} from 'swiper/modules';

export default class extends Controller {
    connect() {
        this._swiper = new Swiper(this.element, {
            modules: [Autoplay],
            direction: 'vertical',
            autoHeight: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            on: {
                init: (swiper) => {
                    this._resizeSlide(swiper)
                },
                slideChange: (swiper) => {
                    this._resizeSlide(swiper)
                },
                resize: (swiper) => {
                    this._resizeSlide(swiper)
                }
            }
        })
    }

    _resizeSlide(swiper) {
        const currentSlide = swiper.slides[swiper.activeIndex]
        const innerSlide = currentSlide?.firstChild?.nextElementSibling
        if (innerSlide) this.element.style.height = `${innerSlide.clientHeight}px`
    }
}

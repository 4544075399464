import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['input', 'button']
    static values = {show: {type: Boolean, default: false}, showTitle: String, hideTitle: String}

    connect() {
        this.showValue === true ? this._showPassword() : this._hidePassword()
    }

    toggleStatus(_event) {
        if (this.inputTarget.type === 'password') {
            this._showPassword()
            this.showValue = true
        } else {
            this._hidePassword()
            this.showValue = false
        }
    }

    _showPassword() {
        this.inputTarget.type = 'text'
        this.buttonTarget.classList.add('show_password_btn--show_password')
        this.buttonTarget.title = this.hideTitleValue
    }

    _hidePassword() {
        this.inputTarget.type = 'password'
        this.buttonTarget.classList.remove('show_password_btn--show_password')
        this.buttonTarget.title = this.showTitleValue
    }
}

import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this.intersectionObserver = new IntersectionObserver(entries => {
                if (entries[0]['intersectionRatio'] === 0) {
                    requestAnimationFrame(() => {
                        this.element.scrollIntoView()
                    })
                    this.intersectionObserver.unobserve(this.element)
                } else {
                    this.intersectionObserver.unobserve(this.element)
                }
            }
        )

        this.intersectionObserver.observe(this.element)
    }

    disconnect() {
        this.intersectionObserver.unobserve(this.element)
    }
}

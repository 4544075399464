// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers/index'
import './controllers/application/index'

import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/offcanvas'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/tab'
import './components/font_awesome'

document.addEventListener('turbo:load', (_event) => {
    /**
     * If browser back button was used, flush cache
     * This ensures that user will always see an accurate, up-to-date view based on their state
     * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
     */
    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    }
})

import {Controller} from '@hotwired/stimulus'
import Bugsnag from '@bugsnag/js'

export default class extends Controller {
    connect() {
        if (!this.loadingAllowed()) return

        Bugsnag.start({
            apiKey: 'a1187c27aadbba61db12a6e7e43d9682',
            appVersion: document.body.dataset.appVersion,
            enabledReleaseStages: ['production', 'staging'],
            releaseStage: document.documentElement.dataset.env,
            collectUserIp: false,
            user: {id: document.body.dataset.userId}
        })
    }

    loadingAllowed() {
        return document.body.dataset.cookieConsent === 'true'
    }
}

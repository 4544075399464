import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['trigger', 'triggerLink']

    initialize() {
        this.intersectionObserver = new IntersectionObserver(
            entries => this._processIntersectionEntries(entries),
            {rootMargin: '400px',}
        )
    }

    connect() {
        this.intersectionObserver.observe(this.triggerTarget)
    }

    disconnect() {
        this.intersectionObserver.unobserve(this.triggerTarget)
    }

    _processIntersectionEntries(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) this._loadMore()
        })
    }

    _loadMore() {
        if (this.hasTriggerLinkTarget) this.triggerLinkTarget.click()
    }
}
